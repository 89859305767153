jQuery(document).ready(function () {
    jQuery('.open-menu:not(.mobile-open-menu)').on('click', function () {
        jQuery('.mobile-menu').removeClass('hidden');
        jQuery('.open-menu__item-top').toggleClass('j-open-menu__item-top');
        jQuery('.open-menu__item-middle').toggleClass('j-open-menu__item-middle');
        jQuery('.open-menu__item-bottom').toggleClass('j-open-menu__item-bottom');
    });

    jQuery('.mobile-open-menu').on('click', function () {
        jQuery('.mobile-menu').addClass('hidden');
        jQuery('.open-menu__item-top').toggleClass('j-open-menu__item-top');
        jQuery('.open-menu__item-middle').toggleClass('j-open-menu__item-middle');
        jQuery('.open-menu__item-bottom').toggleClass('j-open-menu__item-bottom');
    });

//     jQuery(window).on('resize', function () {
//         if(jQuery(window).width() < 991){
//             jQuery('.menu-wrap').appendTo('.mobile-menu');
//             jQuery('.icon-dropdown').on('click', function (event) {
//                 console.log("click");
//                 event.preventDefault();
//                 jQuery(this).closest('.menu__item').children('.sub-menu').stop().slideToggle("slow");

//             });
//         }
//         else {
//             jQuery('.menu-wrap').appendTo('.header');

//         }
//     });

//   jQuery(window).trigger('resize');
});


